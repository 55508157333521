import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { FormControl, InputLabel } from 'components';

type SelectProps = MuiSelectProps & {
  label?: React.ReactNode;
};

export function Select({ sx, label, required, value, children, ...rest }: SelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel required={required}>{label}</InputLabel>
      <MuiSelect label={label} value={value || ''} {...rest}>
        {children}
      </MuiSelect>
    </FormControl>
  );
}
