"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDeclineReasonCodeType = exports.OrderDeclineType = void 0;
var OrderDeclineType;
(function (OrderDeclineType) {
    OrderDeclineType["ALREADY_ORDERED"] = "ALREADY_ORDERED";
    OrderDeclineType["PARENTAL_DECISION"] = "PARENTAL_DECISION";
    OrderDeclineType["PATIENT_DECISION"] = "PATIENT_DECISION";
    OrderDeclineType["RELIGIOUS_EXEMPTION"] = "RELIGIOUS_EXEMPTION";
})(OrderDeclineType = exports.OrderDeclineType || (exports.OrderDeclineType = {}));
// Pulled from Aylo API GET Lookup/ImmunizationInjectionRefusalReasonList
var OrderDeclineReasonCodeType;
(function (OrderDeclineReasonCodeType) {
    OrderDeclineReasonCodeType["PARENTAL_DECISION"] = "00";
    OrderDeclineReasonCodeType["RELIGIOUS_EXEMPTION"] = "01";
    OrderDeclineReasonCodeType["PATIENT_DECISION"] = "03";
    OrderDeclineReasonCodeType["ALREADY_ORDERED"] = "04";
})(OrderDeclineReasonCodeType = exports.OrderDeclineReasonCodeType || (exports.OrderDeclineReasonCodeType = {}));
