"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LookupType = void 0;
var LookupType;
(function (LookupType) {
    LookupType["AllergyReactionList"] = "AllergyReactionList";
    LookupType["AllergySourceList"] = "AllergySourceList";
    LookupType["AllergyTypeList"] = "AllergyTypeList";
    LookupType["AOEList"] = "AOEList";
    LookupType["ChiefComplaintOptionList"] = "ChiefComplaintOptionList";
    LookupType["FamilyHistoryICDCodeList"] = "FamilyHistoryICDCodeList";
    LookupType["FamilyHistoryItemList"] = "FamilyHistoryItemList";
    LookupType["FamilyMemberList"] = "FamilyMemberList";
    LookupType["FavoritedTemplateList"] = "FavoritedTemplateList";
    LookupType["GynSymptomList"] = "GynSymptomList";
    LookupType["HospitalizationHistoryItemList"] = "HospitalizationHistoryItemList";
    LookupType["HPICategoryList"] = "HPICategoryList";
    LookupType["HPISmartFormList"] = "HPISmartFormList";
    LookupType["ICDCodeList"] = "ICDCodeList";
    LookupType["ICDGroupList"] = "ICDGroupList";
    LookupType["ICDGroupMemberList"] = "ICDGroupMemberList";
    LookupType["ImmunizationInjectionList"] = "ImmunizationInjectionList";
    LookupType["ImmunizationInjectionLotList"] = "ImmunizationInjectionLotList";
    LookupType["KeywordList"] = "KeywordList";
    LookupType["LocationList"] = "LocationList";
    LookupType["MedicalHistoryItemList"] = "MedicalHistoryItemList";
    LookupType["MedicationList"] = "MedicationList";
    LookupType["ObSymptomList"] = "ObSymptomList";
    LookupType["OrderableItemLabList"] = "OrderableItemLabList";
    LookupType["OrderableItemList"] = "OrderableItemList";
    LookupType["OrderSet"] = "OrderSet";
    LookupType["ProviderList"] = "ProviderList";
    LookupType["PharmacyLookupList"] = "PharmacyLookupList";
    LookupType["RaceCodeList"] = "RaceCodeList";
    LookupType["ResourceList"] = "ResourceList";
    LookupType["ROSCategoryList"] = "ROSCategoryList";
    LookupType["SocialHistoryCategoryList"] = "SocialHistoryCategoryList";
    LookupType["StaffList"] = "StaffList";
    LookupType["SurgicalHistoryItemList"] = "SurgicalHistoryItemList";
    LookupType["TemplateList"] = "TemplateList";
})(LookupType = exports.LookupType || (exports.LookupType = {}));
