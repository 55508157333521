import { MetricsReportPuck, SubscriptionList } from 'bi-report/BiReportApi';
import BiReportApiService from 'bi-report/BiReportApiService';
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Page,
  Select,
  Skeleton,
  Stack,
  Typography,
} from 'components';
import { useParams } from 'hooks';
import { PageRoute } from 'models';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { buildPath, buildTemplatePath } from 'services';
import { getSpacing } from 'theme';
import { practiceMetricsDetailPageRoute } from '../PracticeMetricsDetailPage/PracticeMetricsDetailPage';

export const practiceMetricsPageRouteName = 'practiceMetrics';

class Params {
  reportId: number = 0;
  facilityId: number = 0;
  badgeNumber: string = '';
  reportDate: string = '';
}

export const practiceMetricsPageRoute: PageRoute<Params> = {
  handle: 'Reports',
  templatePath: buildTemplatePath(practiceMetricsPageRouteName, Params),
  getPath: (params) => buildPath(practiceMetricsPageRouteName, params),
};

export const PracticeMetricsPage = () => {
  const { params } = useParams(practiceMetricsPageRouteName, Params);

  // for email link access
  const hasUrlParams =
    params.reportId !== undefined &&
    params.facilityId !== undefined &&
    params.badgeNumber !== undefined;

  const [reportId, setReportId] = useState<number | undefined>(params.reportId);
  const [facilityId, setFacilityId] = useState<number | undefined>(params.facilityId);
  const [badgeNumber, setBadgeNumber] = useState<string | undefined>(params.badgeNumber);
  const reportDate =
    params.reportDate ?? moment().startOf('week').add(1, 'day').format('YYYY-MM-DD');

  const navigate = useNavigate();
  const [reportLists, setReportLists] = useState<SubscriptionList[]>([]);
  const [selectedListId, setSelectedListId] = useState<number>();
  const [reportPucks, setReportPucks] = useState<MetricsReportPuck[]>([]);
  const [loadingReportLists, setLoadingReportLists] = useState<boolean>(false);
  const [loadingPucks, setLoadingPucks] = useState<boolean>(false);

  const [cache, setCache] = useState<Map<string, MetricsReportPuck[]>>(new Map());

  const fetchReportPucks = useCallback(
    (reportId: number, facilityId: number, badgeNumber: string, reportDate: string) => {
      debugger;
      const cacheKey = `${reportId}-${facilityId}-${badgeNumber}-${reportDate}`;
      if (cache.has(cacheKey)) {
        setReportPucks(cache.get(cacheKey) ?? []);
      } else {
        setLoadingPucks(true);
        BiReportApiService.getApi()
          .adminApp.adminAppControllerGetReportPucks(
            reportId!,
            facilityId!,
            badgeNumber!,
            reportDate!
          )
          .then((response) => {
            setLoadingPucks(false);

            const pucks = response.data;
            setReportPucks(pucks);

            setCache((prev) => {
              prev.set(cacheKey, pucks);
              return prev;
            });
          });
      }
    },
    [cache]
  );

  useEffect(() => {
    const fetchSubscriptions = () => {
      setLoadingReportLists(true);
      BiReportApiService.getApi()
        .adminApp.adminAppControllerGetAuthenticatedUsersReportList()
        .then((response) => {
          const reportLists = response.data;

          setLoadingReportLists(false);
          setReportLists(reportLists);

          // if we only have 1 report list, load the pucks
          if (reportLists.length === 1) {
            const list = reportLists[0];
            setReportId(list.reportId);
            setFacilityId(list.facilityId);
            setBadgeNumber(list.badgeNumber);
            setSelectedListId(list.listId);
          }
        });
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (reportId && facilityId && badgeNumber && reportDate) {
      fetchReportPucks(reportId, facilityId, badgeNumber, reportDate);
    }
  }, [reportId, facilityId, badgeNumber, reportDate, fetchReportPucks]);

  const navigateToDetail = (puckId: number) => {
    navigate({
      pathname: practiceMetricsDetailPageRoute.getPath({
        reportId: puckId,
        facilityId: facilityId!,
        badgeNumber: badgeNumber!,
        reportDate: reportDate!,
      }),
    });
  };

  const pageHeader = () => {
    return (
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Typography variant="inherit">{practiceMetricsPageRoute.handle}</Typography>

        <Typography variant="body2">
          Report Date: {moment(reportDate).format('dddd, MM/DD/YYYY')}
        </Typography>
      </Stack>
    );
  };

  const renderReportLists = () => {
    if (!loadingReportLists && reportLists.length === 0) {
      return <Typography>No subscribed reports available</Typography>;
    }

    if (reportLists.length === 1) {
      const listName = reportLists[0].listName;
      return (
        <Typography variant="h6" fontWeight={(theme) => theme.typography.fontWeightLight}>
          {listName}
        </Typography>
      );
    }

    let label = '';
    if (!selectedListId) label = hasUrlParams ? 'Change report' : 'Select a report';

    return (
      <Grid container>
        <Grid item xl={4} lg={6} xs={12}>
          <Select
            label={label}
            value={selectedListId}
            onChange={(e, value) => {
              const listId = Number(e.target.value);
              setSelectedListId(listId);
              const list = reportLists.find((sub) => sub.listId === listId)!;

              setReportId(list.reportId);
              setFacilityId(list.facilityId);
              setBadgeNumber(list.badgeNumber);
            }}
          >
            {reportLists.map((reportList) => (
              <MenuItem key={reportList.listId} value={reportList.listId}>
                {reportList.listName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    );
  };

  const pucks = () => {
    const puckWidth = '350px';

    return (
      <Box display="flex" flexWrap="wrap" gap={2}>
        {loadingPucks
          ? Array.from({ length: 9 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={puckWidth}
                height={370}
              />
            ))
          : reportPucks?.map((puck, index) => {
              return (
                <Stack
                  key={index}
                  border={(theme) => `1px solid ${theme.palette.divider}`}
                  spacing="sm"
                  p={getSpacing('md')}
                  width={puckWidth}
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <Stack spacing="md">
                    <Box dangerouslySetInnerHTML={{ __html: puck.puckHtml }} />

                    <Button variant="contained" onClick={() => navigateToDetail(puck.id)}>
                      Details
                    </Button>
                  </Stack>
                </Stack>
              );
            })}
      </Box>
    );
  };

  const pageBody = () => {
    return (
      <Card>
        <Stack spacing="md">
          {renderReportLists()}

          {pucks()}
        </Stack>
      </Card>
    );
  };

  return (
    <>
      <Page header={pageHeader()}>{pageBody()}</Page>

      <Outlet />
    </>
  );
};
