import { Auth } from 'aws-amplify';
import { store } from 'redux/store';
import { newToast } from '../services/commonService';
import { Api, RequestParams } from './BiReportApi';
import { setAppLoading, setAppToast } from 'redux/appSlice';

export const SHOW_LOADING_HEADER = 'X-Show-Loading';

class BiReportApiService {
  private api: Api<unknown>;

  constructor() {
    this.api = new Api<unknown>({
      baseUrl: process.env.REACT_APP_BI_REPORT_API_ENDPOINT, // replace with your actual base url
      securityWorker: this.securityInterceptor,
      customFetch: this.fetchInterceptor,
    });
  }

  // Define your request interceptor here
  private securityInterceptor = async (): Promise<RequestParams> => {
    // Implement your security logic here. This can include token refresh logic,
    // setting custom request headers etc.

    // For now, just returning empty headers
    return {
      headers: {},
    };
  };

  // Define your fetch interceptor here
  private fetchInterceptor = (
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> => {
    // REQUEST: Modify the request here as needed
    const showLoading = init?.headers?.[SHOW_LOADING_HEADER] !== 'false';

    if (showLoading) store.dispatch(setAppLoading(store.getState().app.loading + 1));

    // Authorization
    return Auth.currentAuthenticatedUser()
      .then((authData) => {
        const token = authData?.signInUserSession?.accessToken?.jwtToken;
        const modifiedInit: RequestInit = {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${token}`,
          },
        };

        return fetch(input, modifiedInit); // Execute the actual fetch request
      })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        return response;
      })
      .catch((error) => {
        // app loading
        if (showLoading) store.dispatch(setAppLoading(store.getState().app.loading - 1));

        store.dispatch(setAppToast(newToast('error', error.toString())));

        throw error; // Re-throw the error so it can be caught by subsequent promises
      })
      .finally(() => {
        // Code here will run whether the request was successful or not
        if (showLoading) store.dispatch(setAppLoading(store.getState().app.loading - 1));
      });
  };

  public getApi() {
    return this.api;
  }
}

const apiService = new BiReportApiService();

export default apiService;
